import React from 'react';

const RefoundItem = ({ refoundItem, goNext }) => {
  return (
    <>
      <div
        className="refound-item"
        onClick={() => {
          goNext(refoundItem.shiprefund_code);
        }}
      >
        <div className="title">
          <span>{refoundItem.shiprefund_code}</span>
          <span>{refoundItem.refund_status}</span>
        </div>
        <div className="number-status">
          <span>{refoundItem.order_no}</span>
          <span>{refoundItem.ecorder_payment}</span>
        </div>
        <div className="product-price">
          {`品項${refoundItem.order_item_qty}・$${refoundItem.refund_amount}`}
        </div>
        <div className="date">{`${refoundItem.apply_refund_date} ${refoundItem.apply_refund_time}`}</div>
      </div>
      <style jsx>{`
        .refound-item {
          padding: 16px 0;
          margin: 0 auto;
          background: #ffffff;
          border-bottom: 1px solid #e4e4e4;
          .title {
            background: rgba(95, 210, 218, 0.25);
            border: 1px solid #5fd2da;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 27px;
            padding: 4px 8px;
            color: #3b3516;
            span:nth-child(2) {
              margin-left: 10px;
            }
          }
          .number-status {
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 23px;
            color: #3b3516;
            span:nth-child(1) {
              font-weight: bold;
            }
          }
          .product-price,
          .date {
            font-size: 14px;
            line-height: 20px;
            color: #999999;
          }
        }
      `}</style>
    </>
  );
};

export default RefoundItem;
