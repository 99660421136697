import React, { useState, useEffect } from 'react';

import Header from '../../components/header-bar/index.jsx';
import RefoundItem from '../../components/refound-item/index.jsx';
import Cross from '../../images/icons/i-cross-black.svg';
import { callAppFunc } from '../../utils/jsbridge/index.js';
import { navigate } from 'gatsby';
import { useAPI } from '../../utils/api';
import { convertDate2StringDate } from '../../utils/_dateHandler';
import InfiniteScroll from 'react-infinite-scroll-component';
import { showLoading, dismissLoading } from '../../utils/loading';
import { pageCheck } from '../../utils/pageCheck.js';

let startDate = '2020-01-01';
let endDate = '2020-12-31';
let currentPage = 1;
let totalPage = 0;
const perPage = 10;

const RefoundList = () => {
  const api = useAPI();
  const [isOpen, setIsOpen] = useState(false);
  const [refoundList, setRefoundList] = useState([]);
  const [filter, setFilter] = useState('1');
  const [hasMore, setHasMore] = useState(false);

  const switchFilter = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      if (filter === '1') {
        const start = new Date();
        const end = new Date();
        end.setMonth(end.getMonth() - 3);

        startDate = convertDate2StringDate(start, '-');
        endDate = convertDate2StringDate(end, '-');
      } else {
        const start = new Date();
        start.setMonth(start.getMonth() - 3);
        const end = new Date();
        end.setMonth(end.getMonth() - 12);

        startDate = convertDate2StringDate(start, '-');
        endDate = convertDate2StringDate(end, '-');
      }
      currentPage = 1;
      totalPage = 0;
      setRefoundList([]);
      getRefoundList();
    }
  };

  const getRefoundList = () => {
    console.log('currentPage', currentPage);
    console.log('totalPage', totalPage);
    if (currentPage === 1 || currentPage !== totalPage) {
      setHasMore(true);
      showLoading();
      api
        .getRefoundList(startDate, endDate, currentPage, perPage)
        .then((res) => {
          console.log('res', res);
          dismissLoading();
          if (currentPage === 1) {
            setRefoundList(res.rows);
          } else {
            if (res.rows.length > 0) {
              const rowArr = res.rows;
              const newArr = [...refoundList, ...rowArr];
              setRefoundList(newArr);
            }
          }
          currentPage = currentPage + 1;
          // console.log('currentPage', currentPage);
          totalPage = Number.parseInt(res.total / perPage);
          if (res.total % perPage !== 0) {
            totalPage++;
          }
        })
        .catch((error) => {
          dismissLoading();
          console.log(error);
        });
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    currentPage = 1;
    totalPage = 0;
    const start = new Date();
    const end = new Date();
    end.setMonth(end.getMonth() - 3);

    startDate = convertDate2StringDate(start, '-');
    endDate = convertDate2StringDate(end, '-');

    getRefoundList();
  }, []);

  return (
    <>
      <div className="refound-list">
        <div className="header">
          <Header
            title={'退訂退款訂單'}
            switchFilter={switchFilter}
            filter
            goBack={() => {
              callAppFunc('backHome', {});
            }}
          />
        </div>
        <InfiniteScroll
          dataLength={refoundList.length}
          next={getRefoundList}
          hasMore={hasMore}
          style={{ height: '100%', overflow: 'unset' }}
        >
          {refoundList &&
            refoundList.map((item, index) => (
              <RefoundItem
                key={index}
                refoundItem={item}
                goNext={(code) => {
                  pageCheck(api, () => {
                    navigate(`/refound/detail/${code}`, {
                      state: {},
                      replace: false,
                    });
                  });
                }}
              />
            ))}
        </InfiniteScroll>

        <div className={`filter ${isOpen ? 'open' : ''}`}>
          <div className="cross">
            <img
              onClick={() => {
                setIsOpen();
              }}
              src={Cross}
              alt="cross"
            />
            <div>篩選</div>
          </div>
          <div className="filter-option">
            <input
              type="radio"
              id="filter1"
              name="filter"
              value="1"
              checked={filter === '1'}
              onChange={(e) => setFilter(e.target.value)}
            />
            <label htmlFor="filter1">
              <span>
                <span></span>
              </span>
              <div>最近三個月訂單</div>
            </label>
          </div>
          <div className="filter-option">
            <input
              type="radio"
              id="filter2"
              name="filter"
              value="2"
              checked={filter === '2'}
              onChange={(e) => setFilter(e.target.value)}
            />
            <label htmlFor="filter2">
              <span>
                <span></span>
              </span>
              <div>三個月以上</div>
            </label>
          </div>
          <div className="button-block">
            <div onClick={() => switchFilter()} className="button">
              確認修改
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .refound-list {
          min-height: 100vh;
          padding: 55px 16px 30px 16px;
          box-sizing: border-box;
          .header {
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
          }
          .filter {
            width: 100%;
            position: fixed;
            left: 0;
            bottom: -100%;
            box-sizing: border-box;
            padding-top: 18px;
            background-color: #ffffff;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            box-shadow: 0px 10px 15px 10px #e0e0e0;
            transition: bottom 0.5s ease;
            &.open {
              bottom: 0;
            }

            .cross {
              position: relative;
              text-align: center;
              margin-bottom: 32px;
              img {
                position: absolute;
                left: 18px;
                top: 2px;
              }
              div {
                font-weight: bold;
                font-size: 16px;
                line-height: 23px;
                color: #000000;
              }
            }
            .filter-option {
              margin: 0 0 16px 18px;
            }
            input[type='radio'] {
              display: none;
            }
            input[type='radio'] + label {
              color: #333333;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                font-size: 18px;
                line-height: 26px;
                color: #333333;
                margin-left: 7px;
              }
            }
            input[type='radio'] + label span {
              width: 14px;
              height: 14px;
              background-color: #d2d2d2;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              span {
                width: 10px;
                height: 10px;
                background-color: #ffffff;
                order-radius: 50%;
              }
            }
            input[type='radio']:checked + label span {
              width: 14px;
              height: 14px;
              background-color: #5fd2da;
              display: flex;
              justify-content: center;
              align-items: center;
              order-radius: 50%;

              span {
                width: 4px;
                height: 4px;
                background-color: #ffffff;
                order-radius: 50%;
              }
            }
            .button-block {
              width: calc(100vw - 32px);
              height: 60px;
              padding: 20px 16px;
              background-color: #ffffff;
              box-shadow: 0px 10px 15px 10px #e0e0e0;
              display: flex;
              justify-content: center;
              align-items: center;
              .button {
                width: 100%;
                height: 60px;
                padding: 0 35px;
                background: #5fd2da;
                border: 1px solid #5fd2da;
                box-sizing: border-box;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                line-height: 29px;
                color: #ffffff;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default RefoundList;
